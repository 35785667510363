import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  FileText, 
  ArrowUpRight, 
  BookOpen, 
  Download,
  Users,
  Calendar,
  Tag,
  ArrowRight
} from 'lucide-react';
import Navigation from './Navigation';
import FloatingDocumentsVisualization from './FloatingDocumentsVisualization';


const Publications = () => {
  const location = useLocation();
  
  useEffect(() => {
    // Scroll to the publication if there's a hash in the URL
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const publications = [
    {
      id: 'self-organizing',
      title: "Active Inference for Self-Organizing Multi-LLM Systems: A Bayesian Thermodynamic Approach to Adaptation",
      authors: ["Research Team"],
      date: "December 2023",
      arxivId: "2412.10425",
      tags: ["Active Inference", "Multi-LLM Systems", "Bayesian Methods"],
      abstract: "This paper introduces a novel approach to creating adaptive language agents by integrating active inference with large language models (LLMs). While LLMs demonstrate remarkable capabilities, their reliance on static prompts limits adaptation to new information and changing environments. We address this by implementing an active inference framework that acts as a cognitive layer above an LLM-based agent, dynamically adjusting prompts and search strategies through principled information-seeking behavior. Our framework models the environment using three state factors (prompt, search, and information states) with seven observation modalities capturing quality metrics. By framing the agent's learning through the free energy principle, we enable systematic exploration of prompt combinations and search strategies. Experimental results demonstrate the effectiveness of this approach, with the agent developing accurate models of environment dynamics evidenced by emergent structure in observation matrices. Action selection patterns reveal sophisticated exploration-exploitation behavior, transitioning from initial information-gathering to targeted prompt testing. The integration of thermodynamic principles with language model capabilities provides a principled framework for creating robust, adaptable agents, extending active inference beyond traditional low-dimensional control problems to high-dimensional, language-driven environments.",
      impact: "Demonstrates new capabilities in autonomous agent adaptation and self-improvement through advanced active inference implementations."
    },
    {
      id: 'continual-learning',
      title: "Demonstrating the Continual Learning Capabilities of Discrete-Time Active Inference",
      authors: ["Research Team"],
      date: "October 2023",
      arxivId: "2410.00240",
      tags: ["Discrete-Time Active Inference", "Continual Learning", "AI Agents"],
      abstract: "Active inference is a mathematical framework for understanding how agents (biological or artificial) interact with their environments, enabling continual adaptation and decision-making. It combines Bayesian inference and free energy minimization to model perception, action, and learning in uncertain and dynamic contexts. Unlike reinforcement learning, active inference integrates exploration and exploitation seamlessly by minimizing expected free energy. In this paper, we present a continual learning framework for agents operating in discrete time environments, using active inference as the foundation. We derive the mathematical formulations of variational and expected free energy and apply them to the design of a self-learning research agent. This agent updates its beliefs and adapts its actions based on new data without manual intervention. Through experiments in changing environments, we demonstrate the agent's ability to relearn and refine its models efficiently, making it suitable for complex domains like finance and healthcare. The paper concludes by discussing how the proposed framework generalizes to other systems, positioning active inference as a flexible approach for adaptive AI.",
      impact: "Provides important previously non-formalized insights into the continual learning capabilities of discrete-time active inference."
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <Navigation />

      {/* Hero Section */}
      <section className="pt-32 pb-20 bg-white">
        <div className="container mx-auto max-w-6xl px-6">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-2xl sm:text-3xl md:text-5xl font-bold text-gray-900 mb-6">
              Research Publications
            </h1>
            <p className="text-base sm:text-lg md:text-xl text-gray-600">
              Advancing the field of AI through groundbreaking research in active inference and autonomous systems.
            </p>
          </div>
          <FloatingDocumentsVisualization />
        </div>
      </section>

      {/* Publications List */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto max-w-6xl px-6">
          <div className="space-y-12">
            {publications.map((pub) => (
              <div key={pub.id} id={pub.id} className="bg-white rounded-xl p-8 shadow-sm">
                <div className="flex items-start gap-6">
                  <div className="flex-shrink-0">
                    <FileText className="text-blue-600" size={32} />
                  </div>
                  <div className="flex-grow">
                    <div className="flex items-start justify-between gap-4">
                    <h2 className="text-base sm:text-xl md:text-2xl font-bold text-gray-900 mb-4">{pub.title}</h2>
                    <a 
                      href={`https://arxiv.org/abs/${pub.arxivId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex-shrink-0 inline-flex items-center text-blue-600 hover:text-blue-700"
                    >
                      <ArrowUpRight size={20} />
                    </a>
                    </div>

                    <div className="flex flex-wrap gap-2 sm:gap-4 mb-6">
                      <div className="flex items-center text-gray-600 text-[10px] xs:text-xs sm:text-sm">
                        <Users className="mr-1 sm:mr-2" size={14} />
                        {pub.authors.join(', ')}
                      </div>
                      <div className="flex items-center text-gray-600 text-[10px] xs:text-xs sm:text-sm">
                        <Calendar className="mr-1 sm:mr-2" size={14} />
                        {pub.date}
                      </div>
                    </div>

                    <div className="flex flex-wrap gap-1 sm:gap-2 mb-6">
                      {pub.tags.map((tag, tagIndex) => (
                        <span 
                          key={tagIndex}
                          className="inline-flex items-center px-1.5 sm:px-3 py-0.5 sm:py-1 rounded-full text-[10px] xs:text-xs sm:text-sm bg-blue-50 text-blue-700"
                        >
                          <Tag className="mr-1" size={12} />
                          {tag}
                        </span>
                      ))}
                    </div>

                    <div className="bg-gray-50 p-3 sm:p-4 md:p-6 rounded-lg mb-6">
                      <h3 className="font-semibold text-gray-900 mb-2 text-xs sm:text-sm md:text-base">Abstract</h3>
                      <p className="text-gray-600 text-[10px] xs:text-xs sm:text-sm">{pub.abstract}</p>
                    </div>

                    <div className="bg-blue-50 p-3 sm:p-4 md:p-6 rounded-lg mb-6">
                      <h3 className="font-semibold text-gray-900 mb-2 text-xs sm:text-sm md:text-base">Impact</h3>
                      <p className="text-gray-600 text-[10px] xs:text-xs sm:text-sm">{pub.impact}</p>
                    </div>

                    <div className="flex flex-wrap gap-4">
                      <a 
                        href={`https://arxiv.org/pdf/${pub.arxivId}.pdf`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center px-4 py-2 bg-white border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition duration-300"
                      >
                        <Download className="mr-2" size={16} />
                        Download PDF
                      </a>
                      <a 
                        href={`https://arxiv.org/abs/${pub.arxivId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center px-4 py-2 bg-white border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition duration-300"
                      >
                        <BookOpen className="mr-2" size={16} />
                        View on arXiv
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Research Updates */}
      <section className="py-20 bg-white">
        <div className="container mx-auto max-w-6xl px-6 text-center">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Stay Updated on Our Research</h2>
          <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
            Follow our latest publications and research developments in AI agents and autonomous systems.
          </p>
          <Link to="/contact" className="inline-flex items-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300">
            Contact Research Team <ArrowRight className="ml-2" size={16} />
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Publications;