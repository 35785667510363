import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './LandingPage';
import Research from './Research';
import Products from './Products';
import Publications from './Publications';
import About from './About';
import Contact from './Contact';
import ActiveInference from './ActiveInference';
import CustomAgents from './CustomAgents';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/research" element={<Research />} />
          <Route path="/products" element={<Products />} />
          <Route path="/publications" element={<Publications />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/research/active-inference" element={<ActiveInference />} />
          <Route path="/custom-agents" element={<CustomAgents />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;