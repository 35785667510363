import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Brain, 
  Target, 
  Compass, 
  Lightbulb,
  Users,
  ArrowRight
} from 'lucide-react';
import Navigation from './Navigation';

const About = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Navigation />

      {/* Hero Section */}
      <section className="pt-32 pb-20 bg-white">
        <div className="container mx-auto max-w-6xl px-6">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
              Pioneering the Future of AI Agents
            </h1>
            <p className="text-xl text-gray-600">
              Lexal is a research organization dedicated to advancing autonomous AI systems through groundbreaking research in active inference and meta-learning frameworks.
            </p>
          </div>
        </div>
      </section>

      {/* Mission & Vision */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto max-w-6xl px-6">
          <div className="grid md:grid-cols-2 gap-12">
            <div className="bg-white p-8 rounded-xl shadow-sm">
              <div className="flex items-center gap-4 mb-6">
                <Target className="text-blue-600" size={32} />
                <h2 className="text-2xl font-bold text-gray-900">Our Mission</h2>
              </div>
              <p className="text-gray-600 mb-6">
                To develop autonomous AI systems that can learn, adapt, and improve themselves through advanced active inference frameworks, pushing the boundaries of what's possible in artificial intelligence.
              </p>
              <ul className="space-y-4">
                <li className="flex items-start gap-3">
                  <div className="flex-shrink-0 mt-1">
                    <ArrowRight className="text-blue-600" size={16} />
                  </div>
                  <p className="text-gray-600">Pioneer self-improving AI agent architectures that adapt intelligently to new challenges</p>
                </li>
                <li className="flex items-start gap-3">
                  <div className="flex-shrink-0 mt-1">
                    <ArrowRight className="text-blue-600" size={16} />
                  </div>
                  <p className="text-gray-600">Advance the field of meta-analysis generation to accelerate research synthesis and knowledge discovery</p>
                </li>
                <li className="flex items-start gap-3">
                  <div className="flex-shrink-0 mt-1">
                    <ArrowRight className="text-blue-600" size={16} />
                  </div>
                  <p className="text-gray-600">Bridge the gap between theoretical AI research and practical applications</p>
                </li>
              </ul>
            </div>

            <div className="bg-white p-8 rounded-xl shadow-sm">
              <div className="flex items-center gap-4 mb-6">
                <Compass className="text-blue-600" size={32} />
                <h2 className="text-2xl font-bold text-gray-900">Our Vision</h2>
              </div>
              <p className="text-gray-600 mb-6">
                We envision a future where AI systems can autonomously learn and evolve, becoming more capable and adaptable while maintaining reliability and transparency in their decision-making processes.
              </p>
              <ul className="space-y-4">
                <li className="flex items-start gap-3">
                  <div className="flex-shrink-0 mt-1">
                    <ArrowRight className="text-blue-600" size={16} />
                  </div>
                  <p className="text-gray-600">Create AI systems that can understand and improve their own capabilities</p>
                </li>
                <li className="flex items-start gap-3">
                  <div className="flex-shrink-0 mt-1">
                    <ArrowRight className="text-blue-600" size={16} />
                  </div>
                  <p className="text-gray-600">Enable faster, more comprehensive research synthesis across all fields</p>
                </li>
                <li className="flex items-start gap-3">
                  <div className="flex-shrink-0 mt-1">
                    <ArrowRight className="text-blue-600" size={16} />
                  </div>
                  <p className="text-gray-600">Foster collaboration between AI research and practical applications</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Research Approach */}
      <section className="py-20 bg-white">
        <div className="container mx-auto max-w-6xl px-6">
          <h2 className="text-3xl font-bold text-gray-900 mb-12 text-center">Our Approach</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="bg-gray-50 p-6 rounded-xl">
              <div className="text-blue-600 mb-4">
                <Brain size={32} />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Active Inference Framework</h3>
              <p className="text-gray-600">
                We leverage advanced active inference principles to create AI systems that can learn and adapt through experience, similar to biological intelligence.
              </p>
            </div>

            <div className="bg-gray-50 p-6 rounded-xl">
              <div className="text-blue-600 mb-4">
                <Lightbulb size={32} />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Innovation Focus</h3>
              <p className="text-gray-600">
                Our research pushes the boundaries of AI capabilities while maintaining practical applicability in real-world scenarios.
              </p>
            </div>

            <div className="bg-gray-50 p-6 rounded-xl">
              <div className="text-blue-600 mb-4">
                <Users size={32} />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Collaborative Research</h3>
              <p className="text-gray-600">
                We actively collaborate with researchers and organizations to accelerate progress in AI agent development.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Impact Section */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto max-w-6xl px-6">
          <div className="bg-white p-8 rounded-xl shadow-sm">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">Our Impact</h2>
            <p className="text-gray-600 mb-8">
              Through our research and development in AI agents and meta-analysis generation, we're helping:
            </p>
            <div className="grid md:grid-cols-2 gap-8">
              <ul className="space-y-4">
                <li className="flex items-start gap-3">
                  <div className="flex-shrink-0 mt-1">
                    <ArrowRight className="text-blue-600" size={16} />
                  </div>
                  <p className="text-gray-600">Accelerate research synthesis and knowledge discovery</p>
                </li>
                <li className="flex items-start gap-3">
                  <div className="flex-shrink-0 mt-1">
                    <ArrowRight className="text-blue-600" size={16} />
                  </div>
                  <p className="text-gray-600">Develop more capable and adaptable AI systems</p>
                </li>
              </ul>
              <ul className="space-y-4">
                <li className="flex items-start gap-3">
                  <div className="flex-shrink-0 mt-1">
                    <ArrowRight className="text-blue-600" size={16} />
                  </div>
                  <p className="text-gray-600">Implement autonomous agents in practical applications</p>
                </li>
                <li className="flex items-start gap-3">
                  <div className="flex-shrink-0 mt-1">
                    <ArrowRight className="text-blue-600" size={16} />
                  </div>
                  <p className="text-gray-600">Drive innovation in AI research and development</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Contact CTA */}
      <section className="py-20 bg-white">
        <div className="container mx-auto max-w-6xl px-6 text-center">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Join Us in Shaping the Future of AI</h2>
          <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
            Whether you're interested in research collaboration, implementing our technology, or learning more about our work, we'd love to hear from you.
          </p>
          <Link to="/contact" className="inline-flex items-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300">
            Get in Touch <ArrowRight className="ml-2" size={16} />
          </Link>
        </div>
      </section>
    </div>
  );
};

export default About;