import React from 'react';
import { Mail, Phone, MapPin, Users, BookOpen, Code } from 'lucide-react';
import Navigation from './Navigation';

const Contact = () => {
  const handleEmailClick = () => {
    window.location.href = 'mailto:business@lexalai.com';
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Navigation />

      {/* Hero Section */}
      <section className="pt-32 pb-20 bg-white">
        <div className="container mx-auto max-w-6xl px-6">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
              Get in Touch
            </h1>
            <p className="text-xl text-gray-600 mb-8">
              Whether you're interested in research collaboration, our products, or learning more about our work, we're here to help.
            </p>
            <button
              onClick={handleEmailClick}
              className="inline-flex items-center px-8 py-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300 transform hover:scale-105"
            >
              <Mail className="mr-2" size={20} />
              Email Us
            </button>
          </div>
        </div>
      </section>

      {/* Contact Options */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto max-w-6xl px-6">
          <div className="grid md:grid-cols-3 gap-8 mb-16">
            <div className="bg-white p-8 rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300">
              <div className="text-blue-600 mb-6">
                <Users size={32} />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Research Collaboration</h3>
              <p className="text-gray-600">
                Interested in collaborating on AI research? Let's explore potential partnerships and advance the field together.
              </p>
            </div>

            <div className="bg-white p-8 rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300">
              <div className="text-blue-600 mb-6">
                <BookOpen size={32} />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Product Inquiries</h3>
              <p className="text-gray-600">
                Learn more about our AI Agent Brain and Meta-Analysis Generator products. We're here to answer your questions.
              </p>
            </div>

            <div className="bg-white p-8 rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300">
              <div className="text-blue-600 mb-6">
                <Code size={32} />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Technical Support</h3>
              <p className="text-gray-600">
                Need help with implementation or have technical questions? Our team is ready to assist you.
              </p>
            </div>
          </div>

          {/* Contact Information Card */}
          <div className="bg-white rounded-xl shadow-sm overflow-hidden">
            <div className="p-8 md:p-12">
              <h2 className="text-2xl font-bold text-gray-900 mb-8">Contact Information</h2>
              <div className="grid md:grid-cols-3 gap-8">
                <div className="flex items-start gap-4">
                  <Mail className="text-blue-600 mt-1" size={24} />
                  <div>
                    <h3 className="font-semibold text-gray-900 mb-2">Email</h3>
                    <a 
                      href="mailto:business@lexalai.com" 
                      className="text-blue-600 hover:text-blue-700 transition-colors"
                    >
                      business@lexalai.com
                    </a>
                  </div>
                </div>

                <div className="flex items-start gap-4">
                  <Phone className="text-blue-600 mt-1" size={24} />
                  <div>
                    <h3 className="font-semibold text-gray-900 mb-2">Phone</h3>
                    <a 
                      href="tel:+17042310333" 
                      className="text-blue-600 hover:text-blue-700 transition-colors"
                    >
                      +1 (704) 231-0333
                    </a>
                  </div>
                </div>

                <div className="flex items-start gap-4">
                  <MapPin className="text-blue-600 mt-1" size={24} />
                  <div>
                    <h3 className="font-semibold text-gray-900 mb-2">Location</h3>
                    <p className="text-gray-600">Chapel Hill, NC</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;