import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Brain,
  BookOpen,
  Zap,
  Settings,
  RefreshCw,
  Search,
  BarChart,
  CheckCircle,
  ArrowRight,
  Code
} from 'lucide-react';
import Navigation from './Navigation';

/**
 * InteractiveTabs Component
 * 
 * - A reusable component that allows switching between different tabs of content.
 * - We'll use it to showcase additional product details in a tabbed format.
 */
const InteractiveTabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="mt-8">
      <div className="flex border-b border-gray-200">
        {tabs.map((tab, index) => (
          <button
            key={index}
            onClick={() => setActiveTab(index)}
            className={`px-2 sm:px-4 py-2 font-semibold transition-colors text-sm sm:text-base ${
              activeTab === index
                ? 'text-blue-600 border-b-4 border-blue-600'
                : 'text-gray-600 hover:text-blue-600'
            }`}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="p-6 bg-gray-50 rounded-b-xl">
        <h3 className="text-xl font-bold mb-4">
          {tabs[activeTab].contentTitle}
        </h3>
        <p className="text-gray-700 whitespace-pre-line">
          {tabs[activeTab].contentText}
        </p>
      </div>
    </div>
  );
};

/**
 * FeatureHoverCard Component
 *
 * - Displays key features in an interactive hover card.
 * - Used below the product sections to highlight additional product capabilities.
 */
const FeatureHoverCard = ({ icon: Icon, title, description }) => {
  return (
    <div className="group relative bg-white rounded-xl p-6 shadow-sm overflow-hidden hover:shadow-md hover:bg-blue-50 transition-all duration-300">
      <div className="flex items-center gap-4 mb-4">
        <Icon className="text-blue-600" size={32} />
        <h4 className="font-semibold text-xl text-gray-900">{title}</h4>
      </div>
      <p className="text-gray-600 group-hover:text-gray-800 transition-colors">
        {description}
      </p>
      {/* Decorative fade effect */}
      <div className="absolute inset-0 bg-gradient-to-r from-transparent to-blue-100 opacity-0 group-hover:opacity-30 transition-opacity duration-300 pointer-events-none" />
    </div>
  );
};

/**
 * PerformanceComparisonTable Component
 *
 * - A simple table comparing product performance metrics.
 * - Demonstrates advanced features or technical metrics for each product.
 */
const PerformanceComparisonTable = () => {
  const data = [
    {
      metric: 'Inference Latency (ms)',
      agentBrain: '~250',
      metaAnalysis: '~400'
    },
    {
      metric: 'Memory Footprint (MB)',
      agentBrain: '512',
      metaAnalysis: '768'
    },
    {
      metric: 'Throughput (Requests/sec)',
      agentBrain: '20',
      metaAnalysis: '15'
    },
    {
      metric: 'Adaptive Overhead',
      agentBrain: 'Moderate',
      metaAnalysis: 'Low'
    },
    {
      metric: 'Scalability',
      agentBrain: 'Horizontal scaling recommended',
      metaAnalysis: 'Vertical scaling recommended'
    }
  ];

  return (
    <div className="overflow-x-auto mt-8">
      <table className="w-full border-collapse bg-white shadow-sm rounded-lg">
        <thead>
          <tr className="bg-gray-100 text-left">
            <th className="px-6 py-3 text-gray-800 font-semibold">
              Metric
            </th>
            <th className="px-6 py-3 text-gray-800 font-semibold">
              AI Agent Brain
            </th>
            <th className="px-6 py-3 text-gray-800 font-semibold">
              Meta-Analysis Generator
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, idx) => (
            <tr
              key={idx}
              className={`border-b last:border-b-0 ${
                idx % 2 === 0 ? 'bg-gray-50' : 'bg-white'
              }`}
            >
              <td className="px-6 py-4 text-gray-700">{row.metric}</td>
              <td className="px-6 py-4 text-gray-700">{row.agentBrain}</td>
              <td className="px-6 py-4 text-gray-700">{row.metaAnalysis}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

/**
 * Products Component
 *
 * - Incorporates new interactive and technical features:
 *   1. InteractiveTabs for expanded product details (technical specs, features, potential use cases).
 *   2. FeatureHoverCard sections to highlight additional product capabilities.
 *   3. A PerformanceComparisonTable for displaying advanced metrics between products.
 */
const Products = () => {
  // Tab data for AI Agent Brain
  const aiAgentBrainTabs = [
    {
      label: 'Implementation',
      contentTitle: 'Implementation Details',
      contentText: `We've built the AI Agent Brain using a combination of Bayesian methods, multi-LLM 
orchestration layers, and integration-friendly APIs. The entire stack is modular, ensuring that 
you can easily incorporate new or specialized language models based on your organization's needs.`
    },
    {
      label: 'Use Cases',
      contentTitle: 'Potential Use Cases',
      contentText: `Our AI Agent Brain can be deployed in various domains:

1. Autonomous customer support.
2. Adaptive virtual assistants in healthcare.
3. Intelligent tutoring systems for e-learning.
4. Financial analysis agents that adjust investment strategies in real-time.`
    }
  ];

  // Tab data for Meta-Analysis Generator
  const metaAnalysisTabs = [
    {
      label: 'Implementation',
      contentTitle: 'Technical Implementation',
      contentText: `Under the hood, the Meta-Analysis Generator utilizes advanced data extraction pipelines, 
similarity search over vector embeddings for semantic grouping of research papers, and a robust text generation 
component to produce summaries that meet academic standards. This workflow drastically reduces the 
time required for comprehensive research reviews.`
    },
    {
      label: 'Use Cases',
      contentTitle: 'Practical Applications',
      contentText: `The Meta-Analysis Generator can accelerate research in:

1. Systematic literature reviews for academic or corporate R&D.
2. Market research, gathering insights across multiple reports.
3. Policy-making, aggregating evidence-based studies to inform decisions.`
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <Navigation />

      {/* Hero Section */}
      <section className="pt-32 pb-20 bg-white">
        <div className="container mx-auto max-w-6xl px-6">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
              Research-Driven AI Products
            </h1>
            <p className="text-xl text-gray-600">
              Cutting-edge AI solutions powered by our research in active inference and autonomous systems.
            </p>
          </div>
        </div>
      </section>

      {/* AI Agent Brain Section with Tabs */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto max-w-6xl px-6">
            <div className="bg-white rounded-xl p-8 shadow-sm mb-12">
            <div className="flex items-center gap-4 mb-8">
                <Brain className="text-blue-600" size={40} />
                <h2 className="text-3xl font-bold text-gray-900">AI Agent Brain</h2>
            </div>

            {/* Video demonstration */}
            <div className="mb-12 flex justify-center">
            <div className="w-11/12 sm:w-4/5 md:w-3/4 lg:w-2/3 xl:w-1/2 mx-auto rounded-lg overflow-hidden bg-gray-50">
                <video 
                className="w-full h-auto rounded-lg shadow-sm object-cover object-center scale-[1.02]"
                style={{
                    marginLeft: '-1%',
                    marginBottom: '-1%'
                }}
                autoPlay 
                loop 
                muted 
                playsInline
                controls={false}
                preload="auto"
                >
                <source src="/agent_brain_visualization.mp4" type="video/mp4" />
                <source src="/agent_brain_visualization.mov" type="video/quicktime" />
                Your browser does not support the video tag.
                </video>
            </div>
            </div>
            {/* Original content preserved below */}
            <div className="grid md:grid-cols-2 gap-12 mb-12">
              <div>
                <p className="text-gray-600 mb-6">
                  A framework for creating self-improving autonomous agents. Our AI Agent Brain leverages active inference and advanced prompt optimization to enable intelligent system adaptation and continuous learning.
                </p>
                <div className="space-y-4">
                  <div className="flex items-start gap-3">
                    <Zap className="text-blue-600 mt-1" size={20} />
                    <div>
                      <h3 className="font-semibold text-gray-900">Self-Improving Architecture</h3>
                      <p className="text-gray-600">
                        Agents that continuously optimize their own behavior and capabilities through active, unprompted learning.
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start gap-3">
                    <Settings className="text-blue-600 mt-1" size={20} />
                    <div>
                      <h3 className="font-semibold text-gray-900">Intelligent Prompt Optimization</h3>
                      <p className="text-gray-600">
                        Dynamic adjustment of prompts and parameters based on performance feedback and environmental signals.
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start gap-3">
                    <RefreshCw className="text-blue-600 mt-1" size={20} />
                    <div>
                      <h3 className="font-semibold text-gray-900">Continuous Adaptation</h3>
                      <p className="text-gray-600">
                        Real-time learning and adaptation to new scenarios and requirements without manual intervention.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Original "Technical Specifications" box */}
              <div className="bg-gray-50 p-6 rounded-lg">
                <h3 className="font-semibold text-gray-900 mb-4">Technical Specifications</h3>
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <CheckCircle className="text-blue-600 mt-1 mr-2" size={16} />
                    <span className="text-gray-600">Built on discrete-time active inference framework</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="text-blue-600 mt-1 mr-2" size={16} />
                    <span className="text-gray-600">Bayesian belief updating mechanisms</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="text-blue-600 mt-1 mr-2" size={16} />
                    <span className="text-gray-600">Multi-LLM coordination capabilities</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="text-blue-600 mt-1 mr-2" size={16} />
                    <span className="text-gray-600">Extensible API for custom integrations</span>
                  </li>
                </ul>
              </div>
            </div>

            {/* New InteractiveTabs Section for AI Agent Brain */}
            <InteractiveTabs tabs={aiAgentBrainTabs} />

            <div className="border-t border-gray-200 pt-8 mt-8">
              <Link
                to="/contact"
                className="inline-flex items-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300"
              >
                Ask us about it <ArrowRight className="ml-2" size={16} />
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Meta-Analysis Generator Section with Tabs */}
      <section className="py-20 bg-white">
        <div className="container mx-auto max-w-6xl px-6">
          <div className="bg-gray-50 rounded-xl p-8 shadow-sm">
            <div className="flex items-center gap-4 mb-8">
              <BookOpen className="text-blue-600" size={40} />
              <h2 className="text-3xl font-bold text-gray-900">Meta-Analysis Generator</h2>
            </div>

            <div className="grid md:grid-cols-2 gap-12 mb-12">
              <div>
                {/* Original content preserved */}
                <p className="text-gray-600 mb-6">
                  Advanced research synthesis tool that automates the creation of comprehensive meta-analyses. Our system processes multiple research papers, reconciles conflicting information, and generates publishable-quality analyses.
                </p>
                <div className="space-y-4">
                  <div className="flex items-start gap-3">
                    <Search className="text-blue-600 mt-1" size={20} />
                    <div>
                      <h3 className="font-semibold text-gray-900">Intelligent Research Synthesis</h3>
                      <p className="text-gray-600">
                        Automatically retrieves and analyzes relevant research papers across multiple sources.
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start gap-3">
                    <BarChart className="text-blue-600 mt-1" size={20} />
                    <div>
                      <h3 className="font-semibold text-gray-900">Cross-Paper Analysis</h3>
                      <p className="text-gray-600">
                        Sophisticated data analysis across multiple papers to identify patterns and relationships.
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start gap-3">
                    <Code className="text-blue-600 mt-1" size={20} />
                    <div>
                      <h3 className="font-semibold text-gray-900">Custom Integration</h3>
                      <p className="text-gray-600">
                        Systematically reconciling conflicting information to ensure robust data-driven reports.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white p-6 rounded-lg">
                <h3 className="font-semibold text-gray-900 mb-4">Key Applications</h3>
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <CheckCircle className="text-blue-600 mt-1 mr-2" size={16} />
                    <div>
                      <span className="font-semibold text-gray-900">
                        Academic Research
                      </span>
                      <p className="text-sm text-gray-600">
                        Accelerate literature reviews and meta-analyses for academic publications
                      </p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="text-blue-600 mt-1 mr-2" size={16} />
                    <div>
                      <span className="font-semibold text-gray-900">
                        Market Research
                      </span>
                      <p className="text-sm text-gray-600">
                        Generate comprehensive market analyses based on published research
                      </p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="text-blue-600 mt-1 mr-2" size={16} />
                    <div>
                      <span className="font-semibold text-gray-900">
                        Strategy Development
                      </span>
                      <p className="text-sm text-gray-600">
                        Create evidence-based strategies using synthesized research findings
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            {/* New InteractiveTabs Section for Meta-Analysis Generator */}
            <InteractiveTabs tabs={metaAnalysisTabs} />

            <div className="border-t border-gray-200 pt-8 mt-8">
              <Link
                to="/contact"
                className="inline-flex items-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300"
              >
                Learn More <ArrowRight className="ml-2" size={16} />
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto max-w-6xl px-6 text-center">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">
            Ready to Transform Your AI Capabilities?
          </h2>
          <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
            Get in touch to learn how our research-driven AI solutions can benefit your organization.
          </p>
          <Link
            to="/contact"
            className="inline-flex items-center px-8 py-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300"
          >
            Contact Us <ArrowRight className="ml-2" size={16} />
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Products;
