/**
 * NetworkVisualization.js
 *
 * A fully self-contained interactive 3D neural network visualization
 * built with React Three Fiber (R3F) and Three.js.
 *
 * Features:
 * 1. Randomly positioned 3D spheres (nodes)
 * 2. Lines (connections) between nodes
 * 3. Animated "data packets" traveling along connections
 * 4. Glowing/pulsing highlights on nodes
 * 5. Mouse interaction that triggers node pulsing
 *
 * NOTE:
 * - Updated to use <sphereGeometry> instead of <sphereBufferGeometry>.
 * - Updated lines to use <bufferGeometry>/<bufferAttribute> inside <line>.
 */

import React, { useRef, useState, useMemo, useEffect } from 'react'
import * as THREE from 'three'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { OrbitControls, Html } from '@react-three/drei'

/**
 * CONFIGURATION CONSTANTS
 * Modify these to adjust the look and feel of the network.
 */
const NUM_NODES = 20         // How many nodes in the scene
const RADIUS = 12           // Rough radius in which nodes are placed
const PACKETS_PER_LINE = 3   // How many packets per connection
const PACKET_SPEED = 0.02    // Speed of traveling data packets
const NODE_SIZE = 0.45       // Radius of each node (sphere)
const LINE_WIDTH = 1.0       // "Fake" thickness for lineBasicMaterial (doesn't truly scale in 3D)
const HOVER_DISTANCE = 3.0   // Distance threshold for mouse hover effect
const CONNECTION_THRESHOLD = 8 // Distance threshold for creating a connection

/**
 * Utility to generate random positions in a rough spherical distribution.
 */
function getRandomPosition(radius) {
  const theta = Math.random() * 2 * Math.PI
  const phi = Math.acos(2 * Math.random() - 1)
  const r = radius * Math.cbrt(Math.random()) // random radius
  const x = r * Math.sin(phi) * Math.cos(theta)
  const y = r * Math.sin(phi) * Math.sin(theta)
  const z = r * Math.cos(phi)
  return new THREE.Vector3(x, y, z)
}

/**
 * Creates an array of node objects (id, position, color).
 * Also determines connections between nodes based on distance.
 */
function useNodeGraph() {
  const nodes = useMemo(() => {
    const newNodes = []
    for (let i = 0; i < NUM_NODES; i++) {
      newNodes.push({
        id: i,
        position: getRandomPosition(RADIUS),
        color: new THREE.Color(`hsl(${Math.random() * 360}, 70%, 50%)`),
      })
    }
    return newNodes
  }, [])

  const connections = useMemo(() => {
    const lines = []
    for (let i = 0; i < nodes.length; i++) {
      for (let j = i + 1; j < nodes.length; j++) {
        const dist = nodes[i].position.distanceTo(nodes[j].position)
        if (dist < CONNECTION_THRESHOLD) {
          lines.push({ source: nodes[i], target: nodes[j], distance: dist })
        }
      }
    }
    return lines
  }, [nodes])

  return { nodes, connections }
}

/**
 * Node component:
 * - Renders a glowing sphere.
 * - Pulses on hover and click.
 */
function Node({ node, mouse }) {
  const ref = useRef()
  const [hovered, setHovered] = useState(false)
  const [active, setActive] = useState(false)

  // Check if mouse is near this node each frame
  useFrame(() => {
    if (!mouse || !ref.current) return
    const distance = ref.current.position.distanceTo(mouse)
    if (distance < HOVER_DISTANCE) {
      setHovered(true)
    } else {
      setHovered(false)
    }
  })

  // Pulse effect (scale) when hovered or clicked
  useFrame(() => {
    if (!ref.current) return
    let targetScale = 1.0
    if (hovered) targetScale = 1.2
    if (active) targetScale = 1.4
    ref.current.scale.lerp(
      new THREE.Vector3(targetScale, targetScale, targetScale),
      0.1
    )
  })

  const emissiveIntensity = hovered || active ? 1.0 : 0.1

  return (
    <mesh
      ref={ref}
      position={node.position}
      onClick={() => setActive(!active)}
    >
      <sphereGeometry args={[NODE_SIZE, 32, 32]} />
      <meshStandardMaterial
        color={node.color}
        emissive={node.color}
        emissiveIntensity={emissiveIntensity}
        roughness={0.3}
        metalness={0.1}
      />
    </mesh>
  )
}

/**
 * Traveling "packet" that moves from source to target node.
 * We animate it with a `progress` value from 0 to 1.
 */
function Packet({ source, target }) {
  const ref = useRef()
  const [progress, setProgress] = useState(Math.random()) // random start

  useFrame(() => {
    let newProgress = progress + PACKET_SPEED
    if (newProgress > 1) {
      newProgress = 0
    }
    setProgress(newProgress)
    const currentPos = new THREE.Vector3().lerpVectors(
      source.position,
      target.position,
      newProgress
    )
    if (ref.current) {
      ref.current.position.copy(currentPos)
    }
  })

  return (
    <mesh ref={ref}>
      <sphereGeometry args={[0.06, 16, 16]} />
      <meshStandardMaterial
        color="#ffffff"
        emissive="#ffffff"
        emissiveIntensity={0.8}
      />
    </mesh>
  )
}

/**
 * A line + multiple Packet components traveling along it.
 */
function Connection({ connection }) {
  const { source, target } = connection

  // Precompute line points as Float32Array
  const pointsArray = useMemo(() => {
    return new Float32Array([
      source.position.x, source.position.y, source.position.z,
      target.position.x, target.position.y, target.position.z
    ])
  }, [source.position, target.position])

  return (
    <group>
      {/* The line itself */}
      <line>
        <bufferGeometry>
          <bufferAttribute
            attach="attributes-position"
            count={pointsArray.length / 3}
            array={pointsArray}
            itemSize={3}
          />
        </bufferGeometry>
        <lineBasicMaterial color="#cccccc" linewidth={LINE_WIDTH} />
      </line>

      {/* Animated data packets along this connection */}
      {Array.from({ length: PACKETS_PER_LINE }).map((_, i) => (
        <Packet key={i} source={source} target={target} />
      ))}
    </group>
  )
}

/**
 * Main 3D Scene with all nodes, connections, camera controls, etc.
 */
function NetworkScene() {
  const { nodes, connections } = useNodeGraph()
  const mouse3D = useRef(new THREE.Vector3(9999, 9999, 9999)) // far away

  const handlePointerMove = (e) => {
    // We have e.point as the 3D point where the raycaster intersects
    // the "invisible" mesh below. We'll store that in mouse3D.
    if (mouse3D.current) {
      mouse3D.current.set(e.point.x, e.point.y, e.point.z)
    }
  }

  return (
    <>
      <ambientLight intensity={0.3} />
      <directionalLight intensity={0.8} position={[10, 10, 10]} castShadow />

      {/* Render the nodes */}
      {nodes.map((node) => (
        <Node key={node.id} node={node} mouse={mouse3D.current} />
      ))}

      {/* Render the connections */}
      {connections.map((connection, i) => (
        <Connection key={i} connection={connection} />
      ))}

      <OrbitControls
        enablePan={true}
        enableZoom={true}
        enableRotate={true}
      />

      {/* Invisible mesh to intercept pointer events for pointerMove */}
      <Html center style={{ pointerEvents: 'none' }} />
      <mesh onPointerMove={handlePointerMove}>
        {/* 
          This mesh has no visible geometry but spans the entire bounding sphere
          so that pointer events are captured. 
        */}
        <sphereGeometry args={[RADIUS * 2, 2, 2]} />
        <meshBasicMaterial transparent opacity={0} />
      </mesh>
    </>
  )
}

/**
 * Main exported component to display the entire network visualization.
 * Render within your CustomAgents page at any size you want.
 */
export default function NetworkVisualization() {
  return (
    <div style={{ width: '100%', height: '500px' }}>
      <Canvas
        camera={{
          position: [0, 0, 30],
          fov: 60
        }}
      >
        <NetworkScene />
      </Canvas>
    </div>
  )
}
