import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Brain, 
  Settings, 
  GitBranch,
  Code,
  CheckCircle,
  ArrowRight,
  Bot
} from 'lucide-react';
import Navigation from './Navigation';
import NetworkVisualization from './NetworkVisualization';


const CustomAgents = () => {
  const exampleProjects = [
    {
      title: "Tool Generator Agent",
      image: "tool_generator.png",
      description: "An AI system that automates the creation, testing, and deployment of function-calling tools for Large Language Models, featuring real-time error correction and a knowledge base with similarity search.",
      link: "https://github.com/RPD123-byte/Agent_Agent"
    },
    {
      title: "Intelligent Query Processor",
      image: "iqp.png",
      description: "Cutting-edge solution leveraging an agentic framework to convert natural language requests into highly accurate database queries, achieving a high success rate with complex, multi-hop queries.",
      link: "https://github.com/RPD123-byte/intelligent_query_processor"
    },
    {
      title: "Delegant",
      image: "delegantbackend.png",
      description: "An agentic framework that helps businesses manage their team's calendar with AI. Features include event management and intelligent team member assignment using NLP.",
      link: "https://github.com/Panchangam18/delegant_agentic_workflow"
    },
    {
      title: "Perry",
      image: "perrybackend.png",
      description: "An agentic framework built to aggregate open source data available on the internet for people research (OSINT), evolving into a comprehensive AI detective system.",
      link: "https://github.com/Panchangam18/periscope_agent_graph"
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <Navigation />

      {/* Hero Section */}
      <section className="pt-32 pb-20 bg-white">
        <div className="container mx-auto max-w-6xl px-6">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
              Custom AI Agent Development
            </h1>
            <p className="text-xl text-gray-600 mb-12">
              Leveraging our AI Agent Brain technology to build tailored autonomous agents for your specific business needs.
            </p>
            {/* Add the NetworkVisualization component here */}
            <NetworkVisualization />
          </div>
        </div>
      </section>

      {/* Service Overview */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto max-w-6xl px-6">
          <div className="bg-white rounded-xl p-8 shadow-sm mb-12">
            <div className="flex items-center gap-4 mb-8">
              <Bot className="text-blue-600" size={40} />
              <h2 className="text-3xl font-bold text-gray-900">Custom Agent Development</h2>
            </div>

            <div className="grid md:grid-cols-2 gap-12 mb-12">
              <div>
                <p className="text-gray-600 mb-6">
                  We specialize in developing custom AI agents tailored to your specific business requirements. By leveraging our AI Agent Brain technology, we create autonomous systems that can handle complex tasks and adapt to your unique workflows.
                </p>
                <div className="space-y-4">
                  <div className="flex items-start gap-3">
                    <Brain className="text-blue-600 mt-1" size={20} />
                    <div>
                      <h3 className="font-semibold text-gray-900">Intelligent Automation</h3>
                      <p className="text-gray-600">Custom-built agents that can handle complex workflows and decision-making processes.</p>
                    </div>
                  </div>
                  <div className="flex items-start gap-3">
                    <Settings className="text-blue-600 mt-1" size={20} />
                    <div>
                      <h3 className="font-semibold text-gray-900">Tailored Integration</h3>
                      <p className="text-gray-600">Seamless integration with your existing systems and workflows.</p>
                    </div>
                  </div>
                  <div className="flex items-start gap-3">
                    <GitBranch className="text-blue-600 mt-1" size={20} />
                    <div>
                      <h3 className="font-semibold text-gray-900">Scalable Architecture</h3>
                      <p className="text-gray-600">Built to grow and adapt with your business needs.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 p-6 rounded-lg">
                <h3 className="font-semibold text-gray-900 mb-4">Development Process</h3>
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <CheckCircle className="text-blue-600 mt-1 mr-2" size={16} />
                    <span className="text-gray-600">Initial consultation and requirements gathering</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="text-blue-600 mt-1 mr-2" size={16} />
                    <span className="text-gray-600">Custom agent design and architecture planning</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="text-blue-600 mt-1 mr-2" size={16} />
                    <span className="text-gray-600">Iterative development with regular feedback</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="text-blue-600 mt-1 mr-2" size={16} />
                    <span className="text-gray-600">Testing and optimization</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="text-blue-600 mt-1 mr-2" size={16} />
                    <span className="text-gray-600">Deployment and ongoing support</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Example Projects */}
      <section className="py-20 bg-white">
        <div className="container mx-auto max-w-6xl px-6">
          <h2 className="text-3xl font-bold text-gray-900 mb-12 text-center">Example AI Agents</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {exampleProjects.map((project, index) => (
              <div key={index} className="bg-gray-50 rounded-xl overflow-hidden shadow-sm transition-all duration-300 hover:shadow-md">
                <img src={project.image} alt={project.title} className="w-full h-48 object-cover" />
                <div className="p-6">
                  <h3 className="font-bold text-xl mb-2 text-blue-600">{project.title}</h3>
                  <p className="text-gray-600 mb-4">{project.description}</p>
                  <a 
                    href={project.link} 
                    className="text-blue-600 hover:text-blue-700 font-semibold transition duration-300 inline-flex items-center"
                  >
                    View Project <ArrowRight className="ml-2" size={16} />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto max-w-6xl px-6 text-center">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Ready to Build Your Custom AI Agent?</h2>
          <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
            Let's discuss how we can create an AI agent tailored to your specific needs and requirements.
          </p>
          <Link 
            to="/contact" 
            className="inline-flex items-center px-8 py-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300"
          >
            Get Started <ArrowRight className="ml-2" size={16} />
          </Link>
        </div>
      </section>
    </div>
  );
};

export default CustomAgents;