import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  Brain, 
  BookOpen, 
  Microscope, 
  ArrowRight,
  ExternalLink,
  Terminal,
  Search,
  Code
} from 'lucide-react';
import Navigation from './Navigation';

// Counter animation hook
const useCounterAnimation = (targetValue, duration = 2000, startOnView = true) => {
  const [count, setCount] = useState(0);
  const [hasStarted, setHasStarted] = useState(!startOnView);

  useEffect(() => {
    if (!hasStarted) return;

    const steps = 50;
    const stepValue = targetValue / steps;
    const stepDuration = duration / steps;

    let currentStep = 0;

    const timer = setInterval(() => {
      currentStep += 1;
      if (currentStep === steps) {
        setCount(targetValue);
        clearInterval(timer);
      } else {
        setCount(Math.floor(currentStep * stepValue));
      }
    }, stepDuration);

    return () => clearInterval(timer);
  }, [targetValue, duration, hasStarted]);

  const startAnimation = () => setHasStarted(true);

  return [count, startAnimation];
};

// Intersection Observer hook
const useIntersectionObserver = (options) => {
  const [ref, setRef] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (ref) {
      const observer = new IntersectionObserver(([entry]) => {
        setIsVisible(entry.isIntersecting);
      }, options);

      observer.observe(ref);

      return () => {
        if (ref) {
          observer.unobserve(ref);
        }
      };
    }
  }, [ref, options]);

  return [setRef, isVisible];
};

const ParticleBackground = () => {
  const canvasRef = React.useRef(null);
  
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;
    let particles = [];

    const resizeCanvas = () => {
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
    };

    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();

    // Create particles
    for (let i = 0; i < 50; i++) {
      particles.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        radius: Math.random() * 2 + 1,
        vx: Math.random() * 2 - 1,
        vy: Math.random() * 2 - 1,
      });
    }

    const drawParticles = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = 'rgba(66, 153, 225, 0.5)';

      particles.forEach((particle, i) => {
        particle.x += particle.vx;
        particle.y += particle.vy;

        if (particle.x < 0 || particle.x > canvas.width) particle.vx *= -1;
        if (particle.y < 0 || particle.y > canvas.height) particle.vy *= -1;

        ctx.beginPath();
        ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
        ctx.fill();

        // Draw connections
        particles.forEach((particle2, j) => {
          if (i === j) return;
          const dx = particle.x - particle2.x;
          const dy = particle.y - particle2.y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < 100) {
            ctx.beginPath();
            ctx.strokeStyle = `rgba(66, 153, 225, ${0.2 * (1 - distance / 100)})`;
            ctx.lineWidth = 1;
            ctx.moveTo(particle.x, particle.y);
            ctx.lineTo(particle2.x, particle2.y);
            ctx.stroke();
          }
        });
      });

      animationFrameId = window.requestAnimationFrame(drawParticles);
    };

    drawParticles();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      window.cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      className="absolute inset-0 w-full h-full opacity-20"
    />
  );
};

const StatCard = ({ icon: Icon, value, label, delay = 0 }) => {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.3 });
  const [count, startAnimation] = useCounterAnimation(value, 2000, false);

  useEffect(() => {
    if (isVisible) {
      startAnimation();
    }
  }, [isVisible, startAnimation]);

  return (
    <div
      ref={ref}
      className="bg-white p-6 rounded-xl shadow-sm transform transition-all duration-500 hover:scale-105"
      style={{
        opacity: isVisible ? 1 : 0,
        transform: `translateY(${isVisible ? 0 : '20px'})`,
        transition: `all 0.6s ease-out ${delay}s`
      }}
    >
      <Icon className="text-blue-600 mb-4" size={32} />
      <div className="text-3xl font-bold text-gray-900 mb-2">
        {count}
      </div>
      <div className="text-gray-600">{label}</div>
    </div>
  );
};

const LandingPage = () => {
    return (
      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
        <Navigation />
        
        {/* Hero Section */}
        <section className="relative pt-32 pb-20 overflow-hidden">
          <ParticleBackground />
          <div className="container mx-auto max-w-6xl px-6 relative">
            <div className="text-center">
              <h1 className="text-5xl md:text-6xl font-bold text-gray-900 mb-6 leading-tight animate-fade-in">
                Pioneering the Future of{' '}
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600">
                  AI Agents
                </span>
              </h1>
              <p className="text-xl text-gray-600 mb-12 max-w-3xl mx-auto animate-fade-in-up">
                Advancing autonomous AI systems through groundbreaking research in active inference 
                and meta-learning frameworks.
              </p>
              <div className="flex justify-center gap-4 animate-fade-in-up">
                <Link 
                  to="/research" 
                  className="group px-8 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all duration-300 transform hover:scale-105"
                >
                  <span className="flex items-center">
                    Explore Research
                    <ArrowRight className="ml-2 transform group-hover:translate-x-1 transition-transform" />
                  </span>
                </Link>
                <Link 
                  to="/products" 
                  className="group px-8 py-3 bg-gray-100 text-gray-800 rounded-lg hover:bg-gray-200 transition-all duration-300 transform hover:scale-105"
                >
                  <span className="flex items-center">
                    View Products
                    <ExternalLink className="ml-2 transform group-hover:translate-x-1 transition-transform" />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </section>
  
        {/* Stats Section */}
        <section className="py-20 bg-gray-50">
          <div className="container mx-auto max-w-6xl px-6">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
              <StatCard
                icon={Brain}
                value={2}
                label="Research Papers Published"
                delay={0}
              />
              <StatCard
                icon={Terminal}
                value={3}
                label="Companies using Research"
                delay={0.2}
              />
              <StatCard
                icon={Search}
                value={6}
                label="Research Team"
                delay={0.4}
              />
              <StatCard
                icon={Code}
                value={2}
                label="Products in Development"
                delay={0.6}
              />
            </div>
          </div>
        </section>
  
        {/* Featured Research */}
        <section className="py-20 bg-white">
          <div className="container mx-auto max-w-6xl px-6">
            <h2 className="text-3xl font-bold text-gray-900 mb-12 text-center">
              Featured Research
            </h2>
            <div className="grid md:grid-cols-2 gap-12">
              <div className="group bg-gray-50 p-8 rounded-xl transition-all duration-300 hover:bg-blue-50 cursor-pointer">
                <div className="text-blue-600 mb-4 transform transition-transform group-hover:scale-110">
                  <Brain size={32} />
                </div>
                <h3 className="text-xl font-semibold mb-4 group-hover:text-blue-600 transition-colors">
                  Self-Organizing Multi-LLM Systems
                </h3>
                <p className="text-gray-600 mb-4">
                  New approach to creating autonomous AI systems using Bayesian thermodynamic 
                  principles and active inference.
                </p>
                <Link 
                  to="/research" 
                  className="inline-flex items-center text-blue-600 hover:text-blue-700"
                >
                  Learn more <ArrowRight className="ml-2 transform group-hover:translate-x-2 transition-transform" />
                </Link>
              </div>
  
              <div className="group bg-gray-50 p-8 rounded-xl transition-all duration-300 hover:bg-blue-50 cursor-pointer">
                <div className="text-blue-600 mb-4 transform transition-transform group-hover:scale-110">
                  <BookOpen size={32} />
                </div>
                <h3 className="text-xl font-semibold mb-4 group-hover:text-blue-600 transition-colors">
                  Meta-Analysis Generation
                </h3>
                <p className="text-gray-600 mb-4">
                  Advanced systems for automated research synthesis and comprehensive meta-analysis generation
                  across multiple domains.
                </p>
                <Link 
                  to="/research" 
                  className="inline-flex items-center text-blue-600 hover:text-blue-700"
                >
                  Learn more <ArrowRight className="ml-2 transform group-hover:translate-x-2 transition-transform" />
                </Link>
              </div>
            </div>
          </div>
        </section>
  
        {/* Latest Publications Preview */}
        <section className="py-20 bg-gray-50">
          <div className="container mx-auto max-w-6xl px-6">
            <h2 className="text-3xl font-bold text-gray-900 mb-12 text-center">
              Latest Publications
            </h2>
            <div className="grid md:grid-cols-2 gap-8">
              <Link 
                to="/publications#self-organizing"
                className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300"
              >
                <div className="flex items-start gap-4">
                  <div className="text-blue-600">
                    <Microscope size={24} />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold mb-2 line-clamp-2">
                      Active Inference for Self-Organizing Multi-LLM Systems
                    </h3>
                    <p className="text-gray-600 text-sm mb-4 line-clamp-3">
                      A Bayesian thermodynamic approach to adaptation in multi-LLM systems, demonstrating
                      advanced prompt optimization and system adaptation capabilities.
                    </p>
                    <span className="text-blue-600 hover:text-blue-700 text-sm inline-flex items-center">
                      Read more on arXiv <ExternalLink size={14} className="ml-1" />
                    </span>
                  </div>
                </div>
              </Link>
  
              <Link
                to="/publications#continual-learning" 
                className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300"
              >
                <div className="flex items-start gap-4">
                  <div className="text-blue-600">
                    <Microscope size={24} />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold mb-2 line-clamp-2">
                      Demonstrating Continual Learning Capabilities of Discrete-Time Active Inference
                    </h3>
                    <p className="text-gray-600 text-sm mb-4 line-clamp-3">
                      Exploring practical applications and continuous learning capabilities in 
                      discrete-time active inference frameworks.
                    </p>
                    <span className="text-blue-600 hover:text-blue-700 text-sm inline-flex items-center">
                      Read more on arXiv <ExternalLink size={14} className="ml-1" />
                    </span>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </section>
  
        {/* Contact CTA */}
        <section className="py-20 bg-white">
          <div className="container mx-auto max-w-6xl px-6 text-center">
            <h2 className="text-3xl font-bold text-gray-900 mb-6">
              Interested in Our Research?
            </h2>
            <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
              We're always open to research collaborations and partnerships. Get in touch to discuss 
              how we can work together.
            </p>
            <Link 
              to="/contact"
              className="inline-flex items-center px-8 py-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all duration-300 transform hover:scale-105"
            >
              Contact Us <ArrowRight className="ml-2" />
            </Link>
          </div>
        </section>
      </div>
    );
  };
  
  export default LandingPage;