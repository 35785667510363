import React, { useState, useEffect, useRef, Suspense } from 'react';
import { Link } from 'react-router-dom';
import * as THREE from 'three'; // <-- Import THREE
import { 
  Network, 
  ArrowRight,
  BarChart,
  ChevronRight
} from 'lucide-react';
import { Canvas } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';
import Navigation from './Navigation';

// Brain Model Component
function BrainModel({ rotation }) {
  const gltf = useGLTF('/scene.gltf');

  // Center the GLTF scene so that it rotates around its true geometric center.
  useEffect(() => {
    if (!gltf.scene) return;

    // Force any transformations to be up-to-date
    gltf.scene.updateMatrixWorld(true);

    // Compute bounding box and find its center
    const box = new THREE.Box3().setFromObject(gltf.scene);
    const center = box.getCenter(new THREE.Vector3());

    // Shift the entire scene so that pivot is at the center
    gltf.scene.position.x -= center.x;
    gltf.scene.position.y -= center.y;
    gltf.scene.position.z -= center.z;

    // Move the model up a bit after centering
    gltf.scene.position.y += 2;
  }, [gltf]);

  return (
    <Suspense fallback={null}>
      <group>
        <primitive 
          object={gltf.scene}
          // Make the brain bigger
          scale={50}
        />
      </group>
    </Suspense>
  );
}

// Research Card Component
const ResearchCard = ({ icon: Icon, title, description, publications, applications }) => {
  const [isOpen, setIsOpen] = useState(false);
  const cardRef = useRef(null);

  useEffect(() => {
    const currentRef = cardRef.current; // Store ref value
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-fade-in-up');
        }
      },
      { threshold: 0.1 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) { // Use stored ref value
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div 
      ref={cardRef}
      className={`bg-white rounded-xl shadow-sm transition-all duration-300 ${
        isOpen ? 'shadow-lg' : ''
      }`}
    >
      <div 
        className="p-8 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center gap-4 mb-6">
          <div className="text-blue-600 transform transition-transform group-hover:scale-110">
            <Icon size={32} />
          </div>
          <h3 className="text-2xl font-bold text-gray-900">{title}</h3>
        </div>
        <p className="text-gray-600 mb-4">
          {description}
        </p>
        <button 
          className="inline-flex items-center text-blue-600 hover:text-blue-700"
        >
          <span className="mr-2">Learn more</span>
          <ChevronRight 
            size={16}
            className={`transform transition-transform ${
              isOpen ? 'rotate-90' : ''
            }`}
          />
        </button>
      </div>

      <div 
        className="overflow-hidden transition-all duration-300"
        style={{
          maxHeight: isOpen ? '500px' : '0',
          opacity: isOpen ? 1 : 0
        }}
      >
        <div className="p-8 pt-0">
          <div className="border-t border-gray-100 pt-6">
            <div className="grid md:grid-cols-2 gap-6">
              <div>
                <h4 className="font-semibold text-gray-900 mb-4">Publications</h4>
                <div className="space-y-4">
                  {publications.map((pub, index) => (
                    <Link 
                      key={index}
                      to={pub.link}
                      className="block p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
                    >
                      <p className="text-sm font-semibold text-gray-900 mb-1">
                        {pub.title}
                      </p>
                      <span className="text-sm text-gray-600">
                        {pub.date}
                      </span>
                    </Link>
                  ))}
                </div>
              </div>
              <div>
                <h4 className="font-semibold text-gray-900 mb-4">Applications</h4>
                <ul className="space-y-3">
                  {applications.map((app, index) => (
                    <li 
                      key={index}
                      className="flex items-start gap-2"
                    >
                      <ArrowRight className="text-blue-600 mt-1" size={16} />
                      <span className="text-gray-600">{app}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Research Visualization Component
const ResearchVisualization = () => {
  const modelRef = useRef();
  const [containerSize, setContainerSize] = useState({
    height: 600,
    cameraZ: 20
  });

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 270) {
        setContainerSize({ height: 200, cameraZ: 35 });
      } else if (width <= 400) {
        setContainerSize({ height: 300, cameraZ: 30 });
      } else if (width <= 620) {
        setContainerSize({ height: 400, cameraZ: 25 });
      } else {
        setContainerSize({ height: 600, cameraZ: 20 });
      }
    };

    handleResize(); // Initial size
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const animate = () => {
      if (modelRef.current) {
        modelRef.current.rotation.y += 0.01;
      }
      requestAnimationFrame(animate);
    };

    const animationFrame = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrame);
  }, []);

  return (
    <div 
      className="relative w-full max-w-4xl mx-auto"
      style={{ height: `${containerSize.height}px` }}
    >
      <div className="absolute inset-0">
        <Canvas 
          camera={{ 
            position: [0, 0, containerSize.cameraZ],
            fov: 45
          }}
        >
          <ambientLight intensity={1} />
          <pointLight position={[10, 10, 10]} intensity={1.5} />
          <pointLight position={[-10, -10, -10]} intensity={0.5} />
          <group ref={modelRef}>
            <BrainModel />
          </group>
        </Canvas>
      </div>
    </div>
  );
};

// Timeline Component
const TimelineItem = ({ date, title, description, isLeft, isVisible }) => {
  return (
    <div 
      className={`flex w-full ${isLeft ? 'justify-start' : 'justify-end'} mb-8`}
      style={{
        opacity: isVisible ? 1 : 0,
        transform: `translateX(${isVisible ? 0 : (isLeft ? '-20px' : '20px')})`,
        transition: 'all 0.6s ease-out'
      }}
    >
      <div className={`w-5/12 ${!isLeft && 'order-1'}`}>
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <span className="text-sm text-blue-600 font-semibold">{date}</span>
          <h3 className="text-lg font-bold mt-1 mb-2">{title}</h3>
          <p className="text-gray-600 text-sm">{description}</p>
        </div>
      </div>
    </div>
  );
};

// Main Research Component
const Research = () => {
  const researchData = {
    multiLLM: {
      title: "Self-Organizing Multi-LLM Systems",
      description: "Our groundbreaking research in multi-LLM systems focuses on creating self-organizing networks of language models that can dynamically adapt and optimize their behavior.",
      publications: [
        {
          title: "Active Inference for Self-Organizing Multi-LLM Systems",
          date: "December 2024",
          link: "/publications#self-organizing"
        },
        {
          title: "Demonstrating Continual Learning Capabilities",
          date: "September 2024",
          link: "/publications#continual-learning"
        }
      ],
      applications: [
        "Autonomous Agent Development",
        "Multi-Agent Coordination",
        "Dynamic System Optimization",
        "Adaptive Learning Systems"
      ]
    },
    metaAnalysis: {
      title: "Automated Meta-Analysis Generation",
      description: "Advanced research synthesis systems capable of processing multiple papers, reconciling conflicting information, and generating comprehensive meta-analyses.",
      publications: [
        {
          title: "Coming Soon",
          date: "2024",
          link: "#"
        }
      ],
      applications: [
        "Academic Research Synthesis",
        "Literature Review Automation",
        "Cross-Study Analysis",
        "Research Validation"
      ]
    }
  };

  const timelineEvents = [
    {
      date: "December 2024",
      title: "Multi-LLM Systems Paper Published",
      description: "Publication of our paper on self-organizing multi-LLM systems."
    },
    {
      date: "September 2024",
      title: "Active Inference Framework",
      description: "Development of our discrete-time active inference implementation."
    },
    {
      date: "March 2024",
      title: "Meta-Analysis Generator Beta",
      description: "Initial development of our automated meta-analysis generation system."
    }
  ];

  const [visibleSections, setVisibleSections] = useState({});

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          setVisibleSections(prev => ({
            ...prev,
            [entry.target.id]: entry.isIntersecting
          }));
        });
      },
      { threshold: 0.1 }
    );

    document.querySelectorAll('section').forEach(section => {
      observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="min-h-screen bg-gray-50">
      <Navigation />

      {/* Hero Section */}
      <section id="hero" className="pt-32 pb-20 bg-white relative overflow-hidden">
        <div className="container mx-auto max-w-6xl px-6">
          <div className="max-w-3xl mx-auto text-center mb-16">
            <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
              Advancing AI Research
            </h1>
            <p className="text-xl text-gray-600">
              Pioneering research in autonomous AI systems and meta-learning frameworks.
            </p>
          </div>
          <ResearchVisualization />
        </div>
      </section>

      {/* Research Areas */}
      <section id="research-areas" className="py-20 bg-gray-50">
        <div className="container mx-auto max-w-6xl px-6">
          <div className="space-y-12">
            <ResearchCard
              icon={Network}
              title={researchData.multiLLM.title}
              description={researchData.multiLLM.description}
              publications={researchData.multiLLM.publications}
              applications={researchData.multiLLM.applications}
            />
            <ResearchCard
              icon={BarChart}
              title={researchData.metaAnalysis.title}
              description={researchData.metaAnalysis.description}
              publications={researchData.metaAnalysis.publications}
              applications={researchData.metaAnalysis.applications}
            />
          </div>
        </div>
      </section>

      {/* Research Timeline */}
      <section id="timeline" className="py-20 bg-white">
        <div className="container mx-auto max-w-6xl px-6">
          <h2 className="text-3xl font-bold text-gray-900 mb-12 text-center">
            Research Timeline
          </h2>
          <div className="relative">
            {/* Timeline line */}
            <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-0.5 bg-gray-200" />
            
            {/* Timeline items */}
            {timelineEvents.map((event, index) => (
              <TimelineItem
                key={index}
                date={event.date}
                title={event.title}
                description={event.description}
                isLeft={index % 2 === 0}
                isVisible={visibleSections['timeline']}
              />
            ))}
          </div>
        </div>
      </section>

      {/* Research Collaboration CTA */}
      <section id="collaboration" className="py-20 bg-gray-50">
        <div className="container mx-auto max-w-6xl px-6 text-center">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">
            Collaborate With Us
          </h2>
          <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
            We're actively seeking research partnerships to advance the field of autonomous AI systems.
          </p>
          <Link 
            to="/contact"
            className="inline-flex items-center px-8 py-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all duration-300 transform hover:scale-105"
          >
            Start Collaboration <ArrowRight className="ml-2" />
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Research;
