/**
 * FloatingDocumentsVisualizationText.js
 *
 * Updated to have:
 * - Larger paper geometry (so there's more space for text)
 * - Smaller font sizes to avoid text overflow
 * - Adjusted positions & lineHeight for better layout
 */

import React, { useRef, useState, useMemo } from 'react'
import * as THREE from 'three'
import { Canvas, useFrame } from '@react-three/fiber'
import { OrbitControls, Environment, Text } from '@react-three/drei'

/**
 * CONFIGURATION CONSTANTS
 */
// Bigger paper dimensions:
const PAPER_WIDTH = 2.0
const PAPER_HEIGHT = 2.8

// Font sizes reduced:
const TITLE_FONT_SIZE = 0.05
const ABSTRACT_FONT_SIZE = 0.028

// Document count and environment
const NUM_PAPERS = 6
const FLOAT_SPEED = 0.75
const HOVER_SCALE = 1.35
const NORMAL_SCALE = 1.0
const SWIRL_STRENGTH = 0.25
const PARTICLE_COUNT = 200
const PAPER_SPREAD = 10
const PARTICLE_SPREAD = 20

/**
 * PAPER 1: Title & Abstract
 */
const TITLE_1 = 
  "Active Inference for Self-Organizing Multi-LLM Systems: A Bayesian Thermodynamic Approach to Adaptation"

const ABSTRACT_1 = 
`This paper introduces a novel approach to creating adaptive language agents by integrating active inference with large language models (LLMs). While LLMs demonstrate remarkable capabilities, their reliance on static prompts limits adaptation to new information and changing environments. We address this by implementing an active inference framework that acts as a cognitive layer above an LLM-based agent, dynamically adjusting prompts and search strategies through principled information-seeking behavior. Our framework models the environment using three state factors (prompt, search, and information states) with seven observation modalities capturing quality metrics. By framing the agent's learning through the free energy principle, we enable systematic exploration of prompt combinations and search strategies. Experimental results demonstrate the effectiveness of this approach, with the agent developing accurate models of environment dynamics evidenced by emergent structure in observation matrices. Action selection patterns reveal sophisticated exploration-exploitation behavior, transitioning from initial information-gathering to targeted prompt testing. The integration of thermodynamic principles with language model capabilities provides a principled framework for creating robust, adaptable agents, extending active inference beyond traditional low-dimensional control problems to high-dimensional, language-driven environments.
`

/**
 * PAPER 2: Title & Abstract
 */
const TITLE_2 = 
  "Demonstrating the Continual Learning Capabilities and Practical Application of Discrete-Time Active Inference"

const ABSTRACT_2 = 
`Active inference is a mathematical framework for understanding how agents (biological or artificial) interact with their environments, enabling continual adaptation and decision-making. It combines Bayesian inference and free energy minimization to model perception, action, and learning in uncertain and dynamic contexts. Unlike reinforcement learning, active inference integrates exploration and exploitation seamlessly by minimizing expected free energy. In this paper, we present a continual learning framework for agents operating in discrete time environments, using active inference as the foundation. We derive the mathematical formulations of variational and expected free energy and apply them to the design of a self-learning research agent. This agent updates its beliefs and adapts its actions based on new data without manual intervention. Through experiments in changing environments, we demonstrate the agent's ability to relearn and refine its models efficiently, making it suitable for complex domains like finance and healthcare. The paper concludes by discussing how the proposed framework generalizes to other systems, positioning active inference as a flexible approach for adaptive AI.
`

/**
 * Utility: random position within a ±spread box
 */
function getRandomPosition(spread) {
  return new THREE.Vector3(
    THREE.MathUtils.randFloatSpread(spread),
    THREE.MathUtils.randFloatSpread(spread),
    THREE.MathUtils.randFloatSpread(spread)
  )
}

/**
 * Utility: random Euler rotation
 */
function getRandomRotation() {
  return new THREE.Euler(
    THREE.MathUtils.randFloat(0, Math.PI * 2),
    THREE.MathUtils.randFloat(0, Math.PI * 2),
    THREE.MathUtils.randFloat(0, Math.PI * 2),
    'XYZ'
  )
}

/**
 * PaperWithText
 * - A group containing:
 *   1. "paper" plane geometry (bigger dimension)
 *   2. Title text & Abstract text (smaller fonts)
 */
function PaperWithText({ position, rotation, title, abstract }) {
  const groupRef = useRef()
  const [hovered, setHovered] = useState(false)

  // Offsets for random floating animation
  const xOffset = useMemo(() => Math.random() * 100, [])
  const yOffset = useMemo(() => Math.random() * 100, [])
  const zOffset = useMemo(() => Math.random() * 100, [])

  useFrame((state) => {
    if (!groupRef.current) return
    const t = state.clock.getElapsedTime() * FLOAT_SPEED

    // Gentle floating effect
    groupRef.current.position.x = position.x + Math.sin(t + xOffset) * 0.25
    groupRef.current.position.y = position.y + Math.sin(t + yOffset) * 0.3
    groupRef.current.position.z = position.z + Math.sin(t + zOffset) * 0.25

    // Slight rotation drift
    groupRef.current.rotation.x += 0.0005
    groupRef.current.rotation.y += 0.0008

    // Hover scale
    const targetScale = hovered ? HOVER_SCALE : NORMAL_SCALE
    groupRef.current.scale.lerp(
      new THREE.Vector3(targetScale, targetScale, targetScale),
      0.1
    )
  })

  return (
    <group
      ref={groupRef}
      rotation={rotation}
      onPointerOver={() => setHovered(true)}
      onPointerOut={() => setHovered(false)}
    >
      {/* The Paper plane */}
      <mesh>
        <planeGeometry args={[PAPER_WIDTH, PAPER_HEIGHT]} />
        <meshStandardMaterial
          color="#fafafa"
          emissive="#ffffff"
          emissiveIntensity={0.1}
          side={THREE.DoubleSide}
        />
      </mesh>

      {/* Title text near the top of the paper */}
      <Text
        position={[0, (PAPER_HEIGHT / 2) - 0.2, 0.01]}
        fontSize={TITLE_FONT_SIZE}
        color="#000000"
        anchorX="center"
        anchorY="top"
        maxWidth={PAPER_WIDTH * 0.9}
        lineHeight={1.2}
      >
        {title}
      </Text>

      {/* Abstract text below the title */}
      <Text
        position={[0, 0.1, 0.01]}  // Lower area for the abstract
        fontSize={ABSTRACT_FONT_SIZE}
        color="#333333"
        anchorX="center"
        anchorY="top"
        maxWidth={PAPER_WIDTH * 0.85}
        lineHeight={1.35}
      >
        {abstract}
      </Text>
    </group>
  )
}

/**
 * KnowledgeStreamParticles
 * - A swirling "knowledge stream" of particles
 */
function KnowledgeStreamParticles() {
  const pointsRef = useRef()
  const [positions] = useState(() => {
    const arr = new Float32Array(PARTICLE_COUNT * 3)
    for (let i = 0; i < PARTICLE_COUNT; i++) {
      arr[i * 3 + 0] = THREE.MathUtils.randFloatSpread(PARTICLE_SPREAD)
      arr[i * 3 + 1] = THREE.MathUtils.randFloatSpread(PARTICLE_SPREAD)
      arr[i * 3 + 2] = THREE.MathUtils.randFloatSpread(PARTICLE_SPREAD)
    }
    return arr
  })

  useFrame((state, delta) => {
    if (!pointsRef.current) return
    const positionsAttr = pointsRef.current.geometry.attributes.position
    for (let i = 0; i < PARTICLE_COUNT; i++) {
      let x = positionsAttr.getX(i)
      let y = positionsAttr.getY(i)
      let z = positionsAttr.getZ(i)

      // Move upwards
      y += 0.03

      // Swirl around Y-axis
      const swirl = SWIRL_STRENGTH * Math.sin((x + z + state.clock.elapsedTime) * 0.3)
      x += swirl * delta
      z -= swirl * delta

      // Reset if too high
      if (y > PARTICLE_SPREAD * 0.5) {
        x = THREE.MathUtils.randFloatSpread(PARTICLE_SPREAD)
        y = -PARTICLE_SPREAD * 0.5
        z = THREE.MathUtils.randFloatSpread(PARTICLE_SPREAD)
      }

      positionsAttr.setXYZ(i, x, y, z)
    }
    positionsAttr.needsUpdate = true
  })

  return (
    <points ref={pointsRef}>
      <bufferGeometry>
        <bufferAttribute
          attach="attributes-position"
          count={positions.length / 3}
          array={positions}
          itemSize={3}
        />
      </bufferGeometry>
      <pointsMaterial
        size={0.06}
        color="#ffffff"
        transparent
        opacity={0.8}
      />
    </points>
  )
}

/**
 * Main Scene
 * - Spawns multiple papers, each either PAPER 1 or PAPER 2 content
 * - Environment lighting
 * - Swirling knowledge stream
 */
function FloatingDocsSceneText() {
  // Alternate between the first paper and second paper for variety
  const papersData = useMemo(() => {
    const data = []
    for (let i = 0; i < NUM_PAPERS; i++) {
      const isEven = i % 2 === 0
      data.push({
        id: i,
        title: isEven ? TITLE_1 : TITLE_2,
        abstract: isEven ? ABSTRACT_1 : ABSTRACT_2,
        position: getRandomPosition(PAPER_SPREAD),
        rotation: getRandomRotation(),
      })
    }
    return data
  }, [])

  return (
    <>
      <ambientLight intensity={0.25} />
      <directionalLight position={[10, 10, 10]} intensity={0.8} />
      <Environment preset="city" background={false} />

      <KnowledgeStreamParticles />

      {papersData.map((paper) => (
        <PaperWithText
          key={paper.id}
          position={paper.position}
          rotation={paper.rotation}
          title={paper.title}
          abstract={paper.abstract}
        />
      ))}
    </>
  )
}

/**
 * Exported Component
 * - No advanced post-processing => no WebGLMultipleRenderTargets usage
 * - Displays 3D floating docs with bigger paper geometry & smaller font
 */
export default function FloatingDocumentsVisualizationText() {
  return (
    <div style={{ width: '100%', height: '800px' }}>
      <Canvas camera={{ position: [0, 0, 10], fov: 60 }}>
        <OrbitControls
          enablePan
          enableZoom
          enableRotate
          maxDistance={50}
          minDistance={5}
          enableDamping
          dampingFactor={0.08}
        />
        <FloatingDocsSceneText />
      </Canvas>
    </div>
  )
}
